import * as Yup from "yup";
import {
  FormikForm,
  FormikSubmit,
  FormikTextField,
} from "@/atoms/account/formik";

const validationSchema = Yup.object().shape({
  login: Yup.string().required("Required."),
  password: Yup.string().required("Required."),
});

const LoginForm = ({
  onSubmit,
  onSuccess,
  submitLabel,
  initialValues = {},
}) => {
  const composedInitialValues = {
    login: "",
    password: "",
    ...(initialValues || {}),
  };
  return (
    <FormikForm
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={composedInitialValues}
      onSuccess={onSuccess}
      submitPristine
    >
      <FormikTextField name={"login"} type={"email"} label={"Email Address"} />
      <FormikTextField
        name={"password"}
        type={"password"}
        label={"Password"}
        isLoginPasswordField
      />
      <FormikSubmit
        className={"mt-4 w-full"}
        label={submitLabel}
        allowPristine
      />
    </FormikForm>
  );
};

LoginForm.defaultProps = {
  submitLabel: "Log In",
};

export default LoginForm;
