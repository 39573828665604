import cx from "classnames";
import { m } from "framer-motion";
import styled from "styled-components";

const StyledCard = styled(m.div)`
  background-color: #fff;
  box-shadow: 0 2px 2px rgba(35, 35, 35, 0.1);
  border-radius: 8px;
  padding: 1rem;
`;

/**
 * @deprecated - old design, use css per use case
 */
const Card = ({ children, className, ...props }) => (
  <StyledCard className={cx("card", className)} {...props}>
    {children}
  </StyledCard>
);

export default Card;
