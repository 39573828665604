import React, { MouseEventHandler, ReactNode } from "react";
import Head from "next/head";
import styled from "styled-components";
import { styles } from "utils/GuidedSignUp";
import DLSHeader from "components/DLS/DLSHeader";
import { BreakpointKey } from "theme/breakpoints";
import DLSProgressMeter from "components/DLS/DLSProgressMeter";

/** This is the className that will be pre-pended to all of StickyHeaderLayout's inner component classNames */
export const STICKY_HEADER_LAYOUT_CLASS_BASE = "sticky-header-layout";

/** These are all of the internal classNames that are used within the StickyHeaderLayout component */
export const StickyHeaderLayoutClassName = {
  BASE: STICKY_HEADER_LAYOUT_CLASS_BASE,
  HEADER_CONTAINER: `${STICKY_HEADER_LAYOUT_CLASS_BASE}-header-container`,
  DLS_HEADER: `${STICKY_HEADER_LAYOUT_CLASS_BASE}-dls-header`,
  PROGRESS_METER: `${STICKY_HEADER_LAYOUT_CLASS_BASE}-progress-meter`,
  CONTENT_CONTAINER: `${STICKY_HEADER_LAYOUT_CLASS_BASE}-content-container`,
} as const;

const Container = styled.section`
  ${styles.layoutPage}
`;

const HeaderContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
`;

interface Props {
  $first?: boolean;
}

const ProgressMeter = styled(DLSProgressMeter)<Props>`
  margin: ${({ $first }) => !$first && "0 0 0 2px"};
`;

interface ContentContainerProps {
  $fullWidth?: boolean;
}
const ContentContainer = styled.section<ContentContainerProps>`
  ${styles.layoutContent}
  width: 100%;
  padding-left: ${({ $fullWidth }) => $fullWidth && "0"};
  padding-right: ${({ $fullWidth }) => $fullWidth && "0"};
`;
interface StickyHeaderLayoutProps {
  className?: string;
  pageTitle: string;
  progress?: number;
  loading?: boolean;
  children: ReactNode;
  $fullWidth?: boolean;
  hideBackButton?: boolean;
  onBackClick?: MouseEventHandler;
}
function StickyHeaderLayout({
  className = "",
  progress,
  pageTitle,
  loading,
  children,
  hideBackButton = false,
  $fullWidth,
  onBackClick,
}: StickyHeaderLayoutProps) {
  const cls = StickyHeaderLayoutClassName;

  const isFirstStep = !progress;

  return (
    <Container className={`${cls.BASE} ${className}`}>
      <Head>
        <meta name="robots" content="noindex" />
        <title key="title">{pageTitle}</title>
      </Head>
      <HeaderContainer className={cls.HEADER_CONTAINER}>
        <DLSHeader
          className={cls.DLS_HEADER}
          disableBackButton={isFirstStep}
          hideBackButton={hideBackButton}
          onBackClick={onBackClick}
          suppressBackButtonBreakpoint={BreakpointKey.MD}
        />
        <ProgressMeter
          className={cls.PROGRESS_METER}
          loading={Boolean(loading)}
          progress={progress}
        />
      </HeaderContainer>
      <ContentContainer
        className={cls.CONTENT_CONTAINER}
        $fullWidth={$fullWidth}
      >
        {children}
      </ContentContainer>
    </Container>
  );
}

export default StickyHeaderLayout;
